

import { isElementVisibleOnViewport } from '@yggdrasil/Utils/general'
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutMultiStepV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}


  
  mount() {
    this.totalSteps = parseInt(this.totalSteps)
    this.totalStepsArray = [ ...Array(this.totalSteps).keys() ]
    const body = this.element.querySelectorAll('.elMultiStepBody')
    const headers = this.element.querySelectorAll('.elMultiStepHeader')
    
    this.steps = this.totalStepsArray.map((i) => {
      return {
        body: body[i],
        header: headers[i]
      }
    })

    headers.forEach((header, stepNumber) => {
      header.addEventListener('click', (evt) => {
        evt.preventDefault();
        this.setStep(stepNumber)
      })
    })

    const buttons = this.element.querySelectorAll('[href="#!next-step"], [href="#!prev-step"], [href="#!open-step"]')
    buttons.forEach((button) => {
      const href = button.getAttribute('href')
      if(href === "#!next-step") {
        button.addEventListener('click', (evt) => {
          evt.preventDefault()
          if(button.dataset.disabled == 'true') return
          this.nextStep()
        })
      } else if(href === "#!prev-step") {
        button.addEventListener('click', (evt) => {
          evt.preventDefault()
          if(button.dataset.disabled == 'true') return
          this.prevStep()
        })
      }
    })

    Checkout.store.state.listen((state) => {
      if (state === Checkout.StoreStates.INITIALIZED) {
        document.addEventListener('keydown', (evt) => {
          const activeStep = this.element.querySelector(".elMultiStepBody[data-step-state='active']")
          const checkoutElement = this.element.closest('.elCheckout')
          const activeNextStepButton = activeStep.querySelector('[href="#!next-step"]')
          if (evt.keyCode === 13 && activeNextStepButton && isElementVisibleOnViewport(checkoutElement, 20)) {
            activeNextStepButton.focus()
            this.nextStep()
          }
        })
      }
    })

    Checkout.store.checkout.step.listen((newStep) => {
      this.changeStep(newStep)
    })
  }

  changeStep(newStep) {
    const activeUiStep = this.element.querySelector(".elMultiStepBody[data-step-state='active']").dataset.stepNumber
    if (this.convertUiStepToData(activeUiStep) != newStep) {
      this.setStepActive(newStep)
    }
  }
  
  async validateStep(step) {
    return (
      await Promise.all(
        this.subscribers['validateStep'].map((handler) => {
          return handler(step)
        })
      )
    )?.every((result) => result)
  }

  setStepActive(step) {
    this.subscribers['setStepActive'].forEach((handler) => handler(step))
    this.totalStepsArray.forEach((stepNumber) => {
      const isCurrentStep = stepNumber == step
      const activeText =  isCurrentStep ? "active" : "inactive"

      this.steps[stepNumber].body.setAttribute("data-step-state", activeText);

      const header = this.steps[stepNumber].header
      if (header) {
        header.setAttribute("data-step-state", activeText);
        if (header.children.length > 0) {
          header.children[0].setAttribute("data-step-state", activeText)
        }
      }
    });

    $([document.documentElement, document.body]).animate({
      scrollTop: $(this.element).offset().top - 50
    }, 200);
  }

  setStep(step) {
    Checkout.store.checkout.step.set(step)
  }

  async nextStep() {
    const currentStep = Checkout.store.checkout.step.get()
    if (await this.validateStep(currentStep)) {
      this.setStep(currentStep + 1)
    }
  }

  prevStep() {
    const step = Checkout.store.checkout.step.get() - 1
    this.setStep(step)
  }

  convertUiStepToData(step) {
    return parseInt(step) - 1
  }



}

window["CheckoutMultiStepV2"] = CheckoutMultiStepV2

